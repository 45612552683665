import HomeComp from "../components/Home/HomeComp";
import { useEffect } from "react";
import { homePageData, homePageimageList } from "../data/HomePageData";
import { Helmet } from 'react-helmet-async';
const Home = () => {
//  useEffect(() => {
 //   document.title = "LTM Transport und Autoservice";
 // }, []);
  return (
    <>
       <Helmet>
        <title>LTM Transport und Autoservice</title>
        <meta name="description" content="Bei LTM bieten wir eine Vielzahl von Dienstleistungen an, um Ihren Transport- und Autoservicebedürfnissen gerecht zu werden. Von der Vermietung von Transportern bis hin zur schnellen Lieferung und professionellen Autoreparatur. Entdecken Sie unsere breite Palette an Services und verlassen Sie sich auf unsere Erfahrung und Zuverlässigkeit. Kontaktieren Sie uns noch heute, um mehr zu erfahren und Ihre Anforderungen zu besprechen!" />
      </Helmet>
      <HomeComp  data={homePageData} dataImage={homePageimageList}/>
    </>
  );
};

export default Home;
