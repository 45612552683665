import React, { useEffect } from "react";

//import NavBar from "./components/NavBar";
import GlobalStyle from "./globalStyles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import MobileDetails from "./pages/MobilDetails";
import NotFound from "./pages/NotFound";
import Offer from "./pages/Offer";
import Info from "./pages/Info";
import Navbar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import ReactGA from "react-ga";
import Rent from "./pages/Rent";
import Delivery from "./pages/Delivery";
import Repair from "./pages/Repair";
import { HelmetProvider } from 'react-helmet-async';

//const TRACKING_ID = "G-2QCJXTNZJ7"; // OUR_TRACKING_ID
const TRACKING_ID = "G-Q266L649DG";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <HelmetProvider>
    <Router>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/offer" element={<Offer />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/rent" element={<Rent />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/info" element={<Info />} />
        <Route path="/repair" element={<Repair />} />
        {/* <Route path="/mobil/:id" element={<MobileDetails />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
    </HelmetProvider>
  );
}

export default App;
