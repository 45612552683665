import React, { useEffect, useState } from 'react';
import { Container, Section, MainHeading } from "../../globalStyles";
import { HeroVideo, HeroImg ,HeroSection, HeroText, ButtonWrapper, HeroButton } from './HomeStyles';
import { useNavigate } from "react-router-dom";
import { featuresData } from "../../data/FeaturesData.js";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

const HomeComp = ({ data, dataImage }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex + 1) % dataImage.length
      );
      setIsImageLoaded(false); // Reset isImageLoaded for the new image
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

	const gaEventTracker = useAnalyticsEventTracker("Jetzt Reservieren");
  let navigate = useNavigate();
  //const { title, description } = data;
  const initial = {
    y: 40,
    opacity: 0,
  };
  const animate = {
    y: 0,
    opacity: 1,
  };

  return (
      <HeroSection>
        <>
      {dataImage.map((image, index) => (
        <HeroImg
          key={index}
          src={image}
          alt=""
          isVisible={currentImageIndex === index}
          onLoad={handleImageLoad}
        />
      ))}
    </>
        <Container>
      
          <MainHeading>{data.title}</MainHeading>
          <HeroText>
          {data.titleMain}  
          </HeroText>
        </Container>
      </HeroSection>
  );
  
};

export default HomeComp;
