export const data = [
  {
    to: "/",
   // text: "Startseite",
    id: "startseite",
  },
  {
    to: "/rent",
    text: "Mieten",
  },
  {
    to: "/repair",
    text: "Reparatur",
  },
  {
    to: "/delivery",
    text: "Lieferung",
  },
  // {
  //   to: "/offer",
  //   text: "Angebote",
  // },
  {
    to: "/contact",
    text: "Kontakt",
  },
  {
    to: "/info",
    text: "Über uns",
  },
];
