export const deliveryPageData = {
  titleMain:"Lieferservice", // LTM - 
  title: "Wir bieten Lieferung an!",
  description: "Ab sofort liefern wir Ihre Einkäufe bequem zu Ihnen nach Hause und das zu ausgesprochen günstigen Preisen.",
};

export const deliveryPageCarData = {
  title: "Fahrzeuge im Haus",
  description: "Insgesamt: 9",
  title2: "Spezifikation",
  description2:"3m x 1,70m x 2,25m.",
  description3:"Die Fahrzeuge sind Dreisitzer und  sind teilweise mit Anhängerkupplung ausgestattet.",
};

export const deliveryPageCarPriceData = {
  title: "Preise",
  description1: "Nach Absprache",
  description2: "Unser Service ist täglich von 10 bis 21 Uhr verfügbar.",
  info1: "Bei mehr Kilometern Preis auf Anfrage.",
  info2: "Bei Bedarf auch mit Fahrer.",
};
