import ContactComp from "../components/Contact/ContactComp";
import { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
const Contact = () => {
   // useEffect(() => {
    //    document.title = "Kontakt | LTM";
   //   }, []);
    return ( 
      <>
      <Helmet>
        <title>Kontakt | LTM</title>
        <meta name="description" content="Kontaktieren Sie uns bei LTM für Fragen, Buchungen und Anfragen! Wir stehen Ihnen zur Verfügung, um Ihnen weiterzuhelfen. Egal, ob Sie Informationen zu unseren Dienstleistungen benötigen, einen Termin vereinbaren möchten oder spezifische Anforderungen haben – wir sind hier, um Ihnen zu helfen." />
      </Helmet>
        <ContactComp />
        </>
     );
}
 
export default Contact;