import RentComp from "../components/Rent/RentComp";
import { rentPageData, rentPageCarData,rentPageCarPriceData } from "../data/RentPageData";
import { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
const Rent = () => {
 // useEffect(() => {
  //  document.title = "Mieten | LTM";
 // }, []);
  return (
    <>
           <Helmet>
        <title>Mieten | LTM</title>
        <meta name="description" content="Entdecken Sie unsere Fahrzeugebei LTM! Mit neun Fahrzeugen, darunter Transporter und Pritschen, bieten wir eine vielfältige Auswahl für Ihre Transportbedürfnisse. Von kompakten Transportern für Umzüge bis hin zu geräumigen Pritschen für große Ladungen – wir haben das passende Fahrzeug für Sie." />
      </Helmet>
      <RentComp data={rentPageData} dataCar={rentPageCarData} dataPrice={rentPageCarPriceData} />
    </>
  );
};

export default Rent;
