import InfoComp from "../components/Info/InfoComp";
import { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
const Info = () => {
 // useEffect(() => {
 //   document.title = "Über uns | LTM";
 // }, []);
  return  (
    <> 
       <Helmet>
        <title>Über uns | LTM</title>
        <meta name="description" content="Entdecken Sie die Geschichte hinter LTM und wie unser Unternehmen entstanden ist." />
      </Helmet>
    <InfoComp />;
    </>
    );
};

export default Info;
