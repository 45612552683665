import RepairComp from "../components/Repair/RepairComp";
import { useEffect } from "react";
import { repairPageData, repairPageCarData } from "../data/RepairPageData";
import { Helmet } from 'react-helmet-async';
const Repair = () => {
 // useEffect(() => {
 //   document.title = "Reparatur | LTM";
 // }, []);
  return (
    <>
    <Helmet>
        <title>Reparatur | LTM</title>
        <meta name="description" content="Vertrauen Sie LTM für umfassende Autoreparaturen, TÜV-Prüfungen und Inspektionen! wir sorgen dafür, dass Ihr Fahrzeug wieder in Top-Zustand ist. Vereinbaren Sie noch heute einen Termin!" />
      </Helmet>
      <RepairComp data={repairPageData} dataCar={repairPageCarData}/>
    </>
  );
};

export default Repair;