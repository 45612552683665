export const repairPageData = {
  titleMain:"Reparaturservice", // LTM - 
  title: "Vom Motor bis zur Karosserie – Unsere Autoreparatur deckt alles ab.",
  description: "Vom Öl- und Reifenwechsel bis zum Getriebe – bei uns erhalten Sie alles aus einer Hand, inklusive TÜV-Service.",
};

export const repairPageCarData = {
  title: "Der umfassende Autoservice",
  description: "Unser umfassender Autoservice bietet eine breite Palette von Dienstleistungen für Ihr Fahrzeug an, um sicherzustellen, dass es in einwandfreiem Zustand bleibt. Von regelmäßigen Wartungen über Inspektionen bis hin zum TÜV-Service kümmern wir uns um alles. Wir stehen bereit, um Ölwechsel, Bremsenwechsel, Filterwechsel und mehr durchzuführen, um die Leistung und Sicherheit Ihres Fahrzeugs zu gewährleisten. Darüber hinaus bieten wir Dienstleistungen wie Getriebeaustausch, Sensorersatz, Klimaservice und vieles mehr an, um sicherzustellen, dass Ihr Fahrzeug stets optimal funktioniert. Verlassen Sie sich auf uns, um Ihr Fahrzeug in Top-Zustand zu halten, damit Sie sicher und sorgenfrei auf der Straße unterwegs sind.",
};

