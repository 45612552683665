export const rentPageData = {
  titleMain: "Mietservice", //LTM - 
  title: "Mieten leicht gemacht!",
  description: "Günstig und unkompliziert können Sie bei uns in Lübeck einen Transporter mieten, ob einen Tag, eine Woche, einen Monat oder länger: Wir bieten Ihnen stets ein faires Preis - Leistungsverhältnis und flexible Abhol – und Rückgabezeiten (auch am Wochenende oder später am Abend)",
};

export const rentPageCarData = {
  title: "Fahrzeuge im Haus",
  description: "Insgesamt: 9",
  title2: "Spezifikation",
  description2:"3m x 1,70m x 2,25m.",
  description3:"Die Fahrzeuge sind Dreisitzer und  sind teilweise mit Anhängerkupplung ausgestattet.",
};

export const rentPageCarPriceData = {
  title: "Preise",
  description1: "Mo – Fr. 70€/Tag bis 150 km.",
  description2: "Sa. / So. 100€/Tag bis 150 km.",
  info1: "Bei mehr Kilometern Preis auf Anfrage.",
  info2: "Bei Bedarf auch mit Fahrer.",
};
