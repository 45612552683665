import React from "react";

import { BsFillShieldLockFill } from "react-icons/bs";
import { IoIosOptions } from "react-icons/io";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BiSupport, BiDollar } from "react-icons/bi";
import { GrHostMaintenance } from "react-icons/gr";
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const infoData = [
  {
    name: "",
    description: "Im Corona Lockdown als Idee entstanden, eröffneten wir 2020 mit einem einzigen Transporter vor unserer Haustür unser Unternehmen, soz. als “drittes Standbein” und kamen damit dem situationsbedingt plötzlich erhöhten Bedarf an Transportmöglichkeiten entgegen.\n  Die Nachfrage war von Anfang an immens, und so konnten wir stetig wachsen – inzwischen sind wir mit 7 Fahrzeugen am Start. \n Zunehmend sind einige unserer Fahrzeuge von Handwerksunternehmen fest gebucht. Aber auch private Umzüge, der spontane Sofakauf, oder der Abtransport von Gartenabfällen sind bei uns gut aufgehoben.\nWir würden uns freuen, auch Sie in unserem Kundenstamm willkommen zu heissen!",
    icon: iconStyle(BsFillShieldLockFill),
    imgClass: "one",
  },
  // {
  //   name: "Wochen Angebot",
  //   description:
  //     "Günstig und unkompliziert können Sie bei uns in Lübeck einen Transporter zum Festpreis mieten. Keine versteckten Kosten. Flexible Abholung/Rückgabe. Ab 50€ /Tag.",
  //   icon: iconStyle(IoIosOptions),
  //   imgClass: "two",
  // },
  // {
  // 	name: 'Maintenance',
  // 	description: 'Our code is written in highest standards, which makes it highly sustainable',
  // 	icon: iconStyle(GrHostMaintenance),
  // 	imgClass: 'three',
  // },
  // {
  // 	name: '24/7 Support',
  // 	description: 'Our team is available at all times in case you need us',
  // 	icon: iconStyle(BiSupport),
  // 	imgClass: 'four',
  // },
  // {
  // 	name: 'Price',
  // 	description: 'We offer the highest value/cost ratio',
  // 	icon: iconStyle(BiDollar),
  // 	imgClass: 'five',
  // },
  // {
  // 	name: 'Scalable',
  // 	description:
  // 		'Our servers are located all over the world, therefore improving scalability and speed ',
  // 	icon: iconStyle(AiOutlineCloudUpload),
  // 	imgClass: 'six',
  // },
];
