import React from "react";
import { Container, Section } from "../../globalStyles";
import {
  FeatureText,
  FeatureTitle,
  FeatureWrapper,
  FeatureColumn,
  FeatureImageWrapper,
  FeatureButton,
  FeatureName,
  FeatureTextWrapper,
  FeatureLine,
} from "./NotFoundCompStyles";
import { useNavigate } from "react-router-dom";
import { notFoundData } from "../../data/NotFoundData.js";

const NotFoundComp = () => {
  let navigate = useNavigate();
  const initial = {
    y: 40,
    opacity: 0,
  };
  const animate = {
    y: 0,
    opacity: 1,
  };

  return (
    <Section smPadding="50px 10px" position="relative" inverse minHeight="100vh">
      <Container>
        <FeatureTextWrapper>
          <FeatureTitle>404</FeatureTitle>
        </FeatureTextWrapper>
        <FeatureLine />
        <FeatureWrapper>
          {notFoundData.map((el, index) => (
            <FeatureColumn
              initial={initial}
              animate={animate}
              transition={{ duration: 0.5 + index * 0.1 }}
              key={index}
            >
              {/* <FeatureImageWrapper className={el.imgClass}>
								{el.icon}
							</FeatureImageWrapper> */}
              <FeatureName>{el.name}</FeatureName>
              <FeatureText>{el.description}</FeatureText>
              <FeatureButton
                onClick={() => {
                   navigate("/");
                   window.scrollTo(0, 0);
                }}
              >
                Zurück zur Startseite
              </FeatureButton>
            </FeatureColumn>
          ))}
        </FeatureWrapper>
      </Container>
    </Section>
  );
};

export default NotFoundComp;
