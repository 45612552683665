export const homePageData = {
  titleMain: "Transportprobleme waren gestern  - heute gibt es uns.  ",
  title: "LTM Transport und Autoservice",
};

export const homePageimageList = [
  './assets/shuffel/1.jpeg',
  './assets/shuffel/2.jpeg',
  './assets/shuffel/3.jpeg',
  './assets/shuffel/4.jpeg',
  './assets/shuffel/5.jpeg',
  // Add more image paths as needed
];