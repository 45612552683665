import DeliveryComp from "../components/Delivery/DeliveryComp";
import { useEffect } from "react";
import { deliveryPageData, deliveryPageCarData,deliveryPageCarPriceData } from "../data/DeliveryPageData";
import { Helmet } from 'react-helmet-async';
const Delivery = () => {
 // useEffect(() => {
  //  document.title = "Lieferung | LTM";
 // }, []);
  return (
    <>
      <Helmet>
        <title>Lieferung | LTM</title>
        <meta name="description" content="Entdecken Sie den Lieferdienst von LTM! Egal ob Haushaltsgeräte, Möbel oder andere Gegenstände – wir liefern zuverlässig und pünktlich an Ihr Ziel. " />
      </Helmet>
      <DeliveryComp data={deliveryPageData} dataCar={deliveryPageCarData} dataPrice={deliveryPageCarPriceData}/>
    </>
  );
};

export default Delivery;