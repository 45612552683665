import { useEffect } from "react";
import { Link } from "react-router-dom";
import NotFoundComp from "../components/NotFound/NotFoundComp";

const NotFound = () => {
    useEffect(() => {
        document.title = "404 Error | LTM";
      }, []);
    return ( 
     <NotFoundComp/>
     );
}
 
export default NotFound;