import React from "react";

import { BsFillShieldLockFill } from "react-icons/bs";
import { IoIosOptions } from "react-icons/io";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BiSupport, BiDollar } from "react-icons/bi";
import { GrHostMaintenance } from "react-icons/gr";
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const contactData = [
  {
    name: "Kontaktdaten",
    description: [
      "Telefon: 015780456850",
      "E-Mail: info@ltm-hl.de",
      "Fax: XXXXXXXXXXXXXXX",
    ],
    icon: iconStyle(BsFillShieldLockFill),
    imgClass: "one",
  },
  {
    name: "Standort",
    description: ["Adresse: Hinter den Kirschkaten 35, 23560 Lübeck (Eingang durch Henschelstraße)"],
    imgSrc: "./assets/location.png",
    icon: iconStyle(BsFillShieldLockFill),
    imgClass: "one",
  },
];
